
// Customizable Area Start
import React from "react";
import CommonSnackbar from '../../../components/src/CommonSnackbar.web'

// Customizable Area End

import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
  Avatar,
  IconButton,
  Popper,
  ClickAwayListener,
  Grow,
  MenuItem,
  Paper,
  Backdrop,
  Snackbar,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./ProductDescription.css";
import {
  EditOutlined as EditIcon,
  Share as ShareIcon,
  KeyboardArrowDown as ArrowDownIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import moment from "moment";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import Loader from "../../../components/src/Loader.web";
import { CustomMenu } from "../../email-account-login/src/CataloguePage.web";
import {  PopUpCommon2 } from "../../notifications/src/Notifications.web";
import { commentStyle} from "../../communityforum/src/TopicDeatils.web";
import { InputField } from "./CreateRescuerPostpage.web";

// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
handleViewBox=(status:string)=>{
 if(status=="Rejected" && this.state.postDetails?.rejected_reason!==null){ 
 return <Box className={`statusLabelFont ${this.state.postDetails.status}`} 
 style={{marginTop:"24px",fontSize:"18px",fontWeight:400,lineHeight:"180%",textTransform:"inherit",marginBottom:"32px",textAlign:"unset",maxHeight:"unset",padding:"16px",borderRadius:"8px"}}>
  {this.state.postDetails.rejected_reason}
  </Box>}
  else if(status=="Disabled" &&this.state.postDetails?.disabled_reason!==null){
    return <Box className={`statusLabelFont ${this.state.postDetails.status}`}
 style={{marginTop:"24px",fontSize:"18px",fontWeight:400,lineHeight:"180%",textTransform:"inherit",marginBottom:"32px",textAlign:"unset",maxHeight:"unset",padding:"16px",borderRadius:"8px"}}>
  {this.state.postDetails.disabled_reason}
   </Box>
  }
  else if(status=="Archived"){
    return <>
    </>
  }
}
 StatusDropdown = ({ isClassToggled, openDropdown, postActions, handleClickOpen }:any) => (
  <Box style={{ position: 'relative' }}  className={isClassToggled ? "statusBtn open" : "statusBtn"}>
    <Button style={{ ...webstyle.Btn }} onClick={openDropdown}
     ref={this.state.anchorRef1}
     aria-controls={Boolean(this.state.isClassToggled) ? 'menu-list-grow' : undefined}
     aria-haspopup="true"
     id="Status"
    >
      Change Status <ArrowDownIcon />
    </Button>
    <Popper   style={{width:"188px",marginTop:"8px",position:"absolute",borderRadius:"8px",background:"#FFF"}} open={isClassToggled} anchorEl={this.state.anchorRef1.current} role={undefined}  disablePortal placement={'bottom-end'}>
                        <Grow
                        style={{
                          opacity: "unset",
     transform: "unset",
    visibility: "unset"
                        }}
                             >
                            <Paper>
                                <ClickAwayListener 
                                onClickAway={(e:any) => {
this.handleClickAway(e)
                                }}>
                                    <CustomMenu autoFocusItem={Boolean(isClassToggled)} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                      
                                      {
                                        postActions.map((action:any, index:number) => (
                                          <MenuItem style={{
                                            ...webstyle.textStyle, color: action.postStatus_text === "Delete Post" ? "#DC2626" : "#0F172A" ,

                                        }}
                                        onClick={() => handleClickOpen(action.postStatus_text)} key={index}   id={`post-status-${index}`}>
                                            {action.postStatus_text}
                                          </MenuItem>
                                        ))}
                                      
                                    </CustomMenu>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                </Popper>
    
  </Box>
);
 handleOption = () => {
  if (this.state.preview &&this.state.pathname=="/petpost-description-owner") {
    const { status} = this.state.postDetails;
    const {  postActionsPublished, postActionsRejected } = this.state;

    let postActions;
    if (status === "Published") {
      postActions = postActionsPublished;
    } else if (status === "Rejected") {
      postActions = postActionsRejected;
    }

    if (postActions) {
      return <this.StatusDropdown isClassToggled={this.state.isClassToggled} openDropdown={this.openDropdown} postActions={postActions} handleClickOpen={this.handleClickOpen} />;
    }
  }
};
handleFosteredBySection=()=>{
  return        this.state.postDetails?.fostered_by !==null && this.state.pathname=="/petpost-description-owner"&&<Box className="proInfo" style={{borderBottom:"unset"}}>
  <Typography  style={{
    color: '#64748B',
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Lato",
    textTransform: 'uppercase',
    lineHeight: '24px',
  }}>Fostered by</Typography>
  <Table className="proDetailtable" >
    <TableBody>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>{this.state.postDetails?.fostered_by?.first_name} {" "}{this.state.postDetails?.fostered_by?.last_name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Email Address</TableCell>
        <TableCell>
{this.state.postDetails?.fostered_by?.email
}
</TableCell>
</TableRow>
<TableRow>
      
      <TableCell>Phone Number</TableCell>
<TableCell>
{this.state.postDetails?.fostered_by?.full_phone_number
}
</TableCell>

</TableRow>

      <TableRow>
        <TableCell>Foster Date</TableCell>
        <TableCell>{moment(this.state.postDetails?.fostered_by?.foster_date).format('DD MMM YYYY')
}</TableCell>
      </TableRow>
    </TableBody>
  </Table>
</Box>
}
handleProfile=()=>{
 return this.state.postDetails?.published_by?.image_url ?<Avatar src={this.state.postDetails?.published_by?.image_url} style={{width:"32px",height:"32px",border: "1px solid #F0E5FF",marginRight:"8px"}} />:
  <Avatar style={{width:"32px",height:"32px",color:"#03B5AA",background:"white",border: "1px solid rgb(205, 240, 238)",marginRight:"8px"}} >
  {
  this.state.postDetails?.published_by?.first_name&& this.state.postDetails?.published_by?.first_name[0].toUpperCase()
  }
  </Avatar>
}
 handlePublished=()=>{
  if(this.handleCondtionForPublishedBy()){
   return <Box className="proInfo1">
    <Typography  style={{
      color: '#64748B',
      fontSize: 16,
      fontWeight: 700,
      fontFamily: "Lato",
      textTransform: 'uppercase',
      lineHeight: '24px',
    }}>Published by</Typography>
    <Box className="proInfo2">
     
  {  this.state.postDetails?.published_by.hasOwnProperty('image_url')&&
     this.handleProfile()}


      <Box className="profileName" >
        <span style={{
        paddingRight:this.state.postDetails?.published_by?.full_name && this.state.postDetails?.published_by?.rescue_group?"8px":"unset"}}> 
      {this.state.postDetails?.published_by?.full_name}</span>{ this.state.postDetails?.published_by?.full_name && this.state.postDetails?.published_by?.rescue_group&&"|" } <span  style={{paddingLeft:this.state.postDetails?.published_by?.full_name ?"8px":"0px"}}className="profileLoaction">{this.state.postDetails?.published_by?.rescue_group}</span></Box>
    </Box>
  </Box>
  }
 }
 returnFields=(heading:string,details:string)=>{
  return  details!==null&&details!==''&&details!==undefined&&<TableRow>
  <TableCell>{heading}</TableCell>
  <TableCell>{details}</TableCell>
</TableRow>
 }
 returnFieldsYesNo=(heading:string,details:boolean)=>{
  return details!==null&&<TableRow>
  <TableCell>{heading}</TableCell>
  <TableCell>{details?"Yes":"No"}</TableCell>
</TableRow>
 }
  hanldeMicrochipeCondition = () => {
    return (this.handleRescuerCondition()) && this.returnFields('Microchip number', this.state.postDetails?.microchip_number)
  }
  handleVaccinationBookCondition = () => {
    return (this.handleRescuerCondition()) && this.state.postDetails?.vaccination_books?.length > 0 && <TableRow>
      <TableCell>{this.handleVaccinationBookName()}</TableCell>
      <TableCell style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
        {
          this.handlePdfCollect()
        }
      </TableCell>
    </TableRow>
  }
  returnTagsBox = () => {
    return <Box className="proInfo" style={{ gap: "16px" }}>
      <TagBox >Gender - {this.state.postDetails?.gender}</TagBox>
      <TagBox >Age -  {this.state.postDetails?.formatted_age && this.handleAgeFormat()}</TagBox>
      {this.state.postDetails?.size !== null && this.state.postDetails?.size !== '' && <TagBox >Size -  {this.state.postDetails?.size}</TagBox>}
      {this.handleShowFields(this.state.postDetails?.cage_size) && <TagBox >Cage Size Required - {this.state.postDetails?.cage_size}</TagBox>}
      {this.handleShowFields(this.state.postDetails?.habitat_size) && <TagBox >Habitat Size Required - {this.state.postDetails?.habitat_size}</TagBox>}
    </Box>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
       <Loader loading={this.state.loader } />
      <CommonSnackbar data-test-id='commonSnackBar1' handleCloseFunc={()=>this.setState({rescuer_email_true:false})} open={this.state.rescuer_email_true} message={`Thank you for the update! Congratulations on ${this.state.postDetails?.name}'s adoption! Your post is now deleted.`} />
      <CommonSnackbar data-test-id='commonSnackBar2'  handleCloseFunc={()=>this.setState({update_post_boolean:false})} open={this.state.update_post_boolean} message={this.state.postStatus=='Rejected'?"AWESOME POSSUM! Your post has been resubmitted and is currently being reviewed. We'll get back to you shortly.":"Changes successfully saved."} />
  <Backdrop open={this.state.isSnackbarOpenForRequest} style={{ zIndex: 700 }} 
                ><SnackBarBackDropStylIng
                    anchorOrigin={{
                      horizontal: "center",
                        vertical: "top",
                    }}
                    open={this.state.isSnackbarOpenForRequest}
                    style={{ zIndex: 1000 }}
                    data-test-id='snackBarForRequestSuccess'
                    onClose={()=>this.setState({isSnackbarOpenForRequest:false,isSnackbarOpenForRequestMessage:''})}
                    autoHideDuration={5000}
                >
                        <Typography style={webstyle.poPupStyle as React.CSSProperties}>
                            <svg xmlns="http://www.w3.org/2000/svg"  style={{minWidth:'26',minHeight:'26'}} width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                            </svg>
                            {this.state.isSnackbarOpenForRequestMessage}
                        </Typography>
                    </SnackBarBackDropStylIng>
                </Backdrop>
            <Box>
        <CommonSnackbar data-test-id='commonSnackBar3' handleCloseFunc={()=>this.setState({updatePost:false})} open={this.state.updatePost} message={this.state.message} />

          
          <MainGrid  >
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: '50px' }}>
              <Grid item xs={12} sm={6}>
              <Box style={{display:"flex",flexDirection:"row",alignItems:"center"}}>

                  <Typography  style={{...webstyle.list,cursor:"pointer"}}  data-test-id="homeTestt" onClick={() => {
                    this.props.navigation.navigate("LandingPageWeb")
                }}>Home</Typography>
           {   this.state.postDetailsCatalogue &&   <>
            <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
            fill="#64748B"
          />
        </svg>
                  <Typography style={{...webstyle.list,cursor:"pointer"}}
                   data-test-id="homePost" 
                   onClick={() => {
                    this.props.navigation.navigate("CataloguePage")
                }}
                  >My Posts</Typography>
           </>
          }
                    <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
            fill="#64748B"
          />
        </svg>
                  <Typography style={{...webstyle.list,color:"#0F172A"}}>Post Details</Typography>
              </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'right' }} className="ersBtncant">
               {this.handleStatusCondition()&& <Button style={{...webstyle.Btn}} 
             data-test-id="editNewPostpage" 
            onClick={()=>{
                this.props.navigation.navigate("editNewPostpage",{id:this.state.postDetails?.id,postDetails:this.state.postDetailsCatalogue})
               }}
               >
                  <EditIcon style={webstyle.iconstyle} /> Edit Post
                </Button>}
                 {this.handleOption()}
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{display:'flex',flexWrap:'wrap'}}>

              <Grid item xs={12} md={12} lg={7} >

                <CarouselDisplay navigation={this.props.navigation} id={"petpost-description-owner"} images={this.state.postDetails}/>
              </Grid>
              <Grid item xs={12} md={12} lg={5}> 
               { this.state.preview &&<Box style={webstyle.dateBox}>
             <Box className={`statusLabel ${this.state.postDetails?.status}`} ><Box >{ this.state.postDetails?.status!=="Published" ?this.handleCheckForStatusTitle(this.state.postDetails?.status):""}</Box></Box>
                  
                  <Box style={webstyle.dateLabel}>{this.handleDate()} <span style={webstyle.boldate}>{this.state.postDetails?.status && moment(this.state.postDetails[this.state.postDetails.status.toLowerCase() + '_at']).format('DD MMM YYYY')}
</span></Box>
                </Box>}
             {this.handleViewBox(this.state.postDetails?.status)}
                <Typography  style={{...webstyle.proTitle, wordWrap: "break-word",}}>
                  {this.state.postDetails?.name}{" "}{"-"}{" "}{this.state.postDetails?.hook_line}
                  </Typography>
                <Typography style={webstyle.prosubTitle}>{this.state.postDetails?.category?.name}, {this.state.postDetails?.sub_category?.name}</Typography>
              {this.state.pathname!=="/petpost-description-owner" && this.state.pathname!=="/previewProductDescription" &&  <Box className="btnCant">
                  <CustomButton data-test-id='adoptPetButton'onClick={()=>this.hanldeNavigateRequestPage('RequestManagement')} style={{...webstyle.BtnGreen,height:"56px",width:this.handleStatus()}}>Adopt Pet</CustomButton>
               { this.state.postDetails?.status=="Published" && <CustomButton data-test-id='fosterPetButton' onClick={()=>this.hanldeNavigateRequestPage('RequestManagementFoster')} style={{...webstyle.BtnGreen,height:"56px",}}>Foster Pet</CustomButton>}
                  <CustomButton
                  data-test-id="favPostid"
                   onClick={this.handleFavPart}style={{...webstyle.Btn,height:"56px",textTransform:"inherit",display:"flex",gap:"8px",background:this.handleFavBackground(),minWidth:this.handleFavWidth()}}>
                    <span style={{display:"flex",gap:"8px",alignItems:"center"}}>
                    <img src={this.handleImageFav() }/>
                   { this.handleHeadingImageFav()}
                    </span>
                    </CustomButton>
                  <Button  style={{...webstyle.Btn,height:"56px",}}onClick={() => {this.handleSharePost();
                  this.copyUrl();
                  }}
                  data-test-id={"shareme"}
                  >
                    <ShareIcon />
                  </Button>
                </Box>}
                {this.returnTagsBox()}
               
             {this.handlePublished()}
                <Box className="proInfo" style={{flexDirection:"column",alignContent:"unset"}}>
                  <Typography  style={{
                    color: '#64748B',
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: "Lato",
                    textTransform: 'uppercase',
                    lineHeight: '24px',
                  }}>Story</Typography>
                  <Typography style={webstyle.infoPara}>
                  {this.state.postDetails?.pet_story} </Typography>
                </Box>
                <Box className="proInfo" >
                  <Typography  style={{
                    color: '#64748B',
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: "Lato",
                    textTransform: 'uppercase',
                    lineHeight: '24px',
                  }}>About Pet</Typography>
                  <Table className="proDetailtable">
                    <TableBody>
                      <TableRow>
                        <TableCell>Hook Line</TableCell>
                        <TableCell>{this.state.postDetails?.hook_line}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Animal Type</TableCell>
                        <TableCell>{this.state.postDetails?.category?.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Breed</TableCell>
                        <TableCell>{this.state.postDetails?.sub_category?.name}</TableCell>
                      </TableRow>
                      {this.state.isShowHideMarketingStrategy&&this.returnFields('Marketing Strategy',this.state.postDetails?.marketing_strategy)}
                      <TableRow>
                        <TableCell>Gender</TableCell>
                        <TableCell>{this.state.postDetails?.gender}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Age</TableCell>
                        <TableCell> {this.handleAgeFormat()}</TableCell>
                      </TableRow>
                      {this.returnFields('Size',this.state.postDetails?.size)}
                      {this.returnFields('Cage Size Required',this.state.postDetails?.cage_size)}
                      {this.returnFields('Habitat Size Required',this.state.postDetails?.habitat_size)}
                      {this.returnFields('Coat Length',this.state.postDetails?.coat_length)}
                      {this.returnFields('Activity Level',this.state.postDetails?.activity_level)}
                      {this.returnFields('Current Location',this.state.postDetails?.location)}
                      {this.returnFields('Noise Level',this.state.postDetails?.noise_level)}
                      

                    </TableBody>
                  </Table>
                </Box>
                <PetHealthBox  style={{borderBottom:this.handleShowPetBehaviour()?'1px solid #E2E8F0':"",}}>
                  <Typography  style={{
                    color: '#64748B',
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: "Lato",
                    textTransform: 'uppercase',
                    lineHeight: '24px',
                  }}>Pet Health</Typography>
                  <Table className="proDetailtable">
                    <TableBody>
                     
                      {this.returnFields('Vaccine Status',this.state.postDetails?.health_vaccine_status)}

                      {this.returnFields('Ehrlichia',this.state.postDetails?.ehrlichia)}
                    
                      {this.returnFields('FIV/FELV Status',this.state.postDetails?.fiv_status)}
                      <TableRow>
                        <TableCell>Microchipped</TableCell>
                        <TableCell>{ this.handleMicrochipped()}</TableCell>
                      </TableRow>
                      {this.hanldeMicrochipeCondition()}
                      {this.returnFieldsYesNo('Wings Clipped', this.state.postDetails?.wings_clipped)}
                      {this.returnFields('PBFD test', this.state.postDetails?.pbfd_test)}
                      {this.returnFieldsYesNo('Dewormed', this.state.postDetails?.dewormed)}
                      {this.returnFieldsYesNo('Spayed/Neutered', this.state.postDetails?.spayed)}
                      {this.returnFields('Skin Diseases', this.state.postDetails?.skin_diseases)}
                      {this.returnFields('Parasite Medication', this.state.postDetails?.parasite_medication)}
                      {this.returnFields('Animal of determination', this.state.postDetails?.animal_of_determination)}
                      {this.returnFields('Medical Conditions', this.state.postDetails?.medical_conditions)}
                      {this.handleVaccinationBookCondition()}
                       </TableBody>
                  </Table>
                </PetHealthBox>
               
                {this.handleShowPetBehaviour()&&<Box className="proInfo" style={{borderBottom:  this.handleBorderBottom()}}>
                  <Typography  style={{
                    color: '#64748B',
                    fontSize: 16,
                    fontWeight: 700,
                    fontFamily: "Lato",
                    textTransform: 'uppercase',
                    lineHeight: '24px',
                  }}>Pet behaviour</Typography>
                  <Table className="proDetailtable" >
                    <TableBody>
                    {this.returnFields('Training',this.state.postDetails?.training)}
                      {this.returnFields('Lifestyle',this.state.postDetails?.life_style)}
                      {this.state.postDetails?.good_in_house_with?.length>0&&this.state.postDetails?.good_in_house_with[0]!=='[]'&&<TableRow>
                        <TableCell>Good in a house with</TableCell>
                        <TableCell>
  {this.handleGoodInHouseWith()
  }
</TableCell>
</TableRow>}
{this.state.postDetails?.temperament?.length>0&&<TableRow>
                  
                      <TableCell>Temperament</TableCell>
<TableCell>
  {this.handleTemperament()
  }
</TableCell>
</TableRow>}

                      <TableRow>
                     
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>}
        {this.handleFosteredBySection()}
              </Grid>
            </Grid>
          </MainGrid>

        </Box>
        {this.state.rescuer_email_false && 
     <CommonSnackbar  data-test-id='commonSnackBar4' handleCloseFunc={()=>this.setState({rescuer_email_false:false})} open={this.state.rescuer_email_false} message={"Thank you! Your post will remain active."} />
     }
             {this.state.already_answer_given && <DiologComponent2 data-test-id='DiologComponent2'
        success={this.state.already_answer_given } handleClose={this.handlethisalready_answer_given} 
        firstHeading={"Answer Previously Received"} 
        secondHeading={'You have already replied to this request and your answer has been recorded.'
     } 
     secondbuttonname={"OKAY"} 
     mainFunction={this.handlethisalready_answer_given}
     />}
        { <DiologComponent 
        hint="Adopted"
        success={this.state.markUsAdoptedPopUp} handleClose={this.handleMarkUsAdoptedClose} 
        firstHeading={"Are you sure you want to mark post as Adopted?"} 
        secondHeading={"The status of the post will be changed to 'Reserved', and the post will no longer be visible to the user." 
     } FirstButtonName={"Cancel"} 
     secondbuttonname={"Confirm"} 
     navigate={this.props.navigation.navigate} 
     mainFunction={this.markAdoptedPost}
     />}
          { <DiologComponent 
          hint="Fostered"
          success={this.state.markUsFosteresPopUp} handleClose={this.handleMarkUsFosteresClose} 
        firstHeading={"Mark as Fostered?"} 
        secondHeading={"" 
     } FirstButtonName={"Cancel"} 
     secondbuttonname={"Confirm"} 
     navigate={this.props.navigation.navigate}
     mainFunction={this.markFosteredPost}
      />}
     {<DiologComponent 
          hint="delete"
          success={this.state.deletePopUp} handleClose={this.handleDeleteClose} 
        firstHeading={"Delete Post?"} 
        secondHeading={"" 
     } FirstButtonName={"Cancel"} 
     secondbuttonname={"Delete Post"} 
     navigate={this.props.navigation.navigate}
     mainFunction={this.handlePutDeletefunction}
     deleteReasonError={this.state.deleteReasonError}
     deleteReason={this.state.deleteReason}
     deletePopUp={true}
     handleDeleteReason={this.handleDeleteReason}
      />}
       <PopUpCommon2
       data-test-id='popUpCommon2'
      firstHeading={`Has ${this.state.post_name} Been Adopted?`}
      firstButtonName={"No, still available"}
      secondButtonName={"Yes, officially adopted!"}
      openState={this.state.openPublished}
      onCloseFn={this.closePublishedPopUp}
      firstButtonFn={()=>this.handleReminder("true")}
      secondButtonFn={()=>this.handleReminder("false")}
      secondButtonwidth="250px"
      firstButtonWidth="140px"
      />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export {ProductDescription}
export const DiologComponent = ({ hint,success, handleClose, firstHeading, secondHeading, FirstButtonName,secondbuttonname ,navigate,mainFunction,deleteReasonError,deleteReason,deletePopUp,handleDeleteReason }: any) => {
let  starIcon = () => {
    return <span style={{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FF5E5B"
    }}>
      {" "}    *
    </span>
}
  return <DiologStyle onClose={handleClose} aria-labelledby="customized-dialog-title" open={success}>
    <DialogTitle id="customized-dialog-title"
      style={{ textAlign: "end", padding: "16px 24px 0px 24px " }}>
      <IconButton aria-label="close" onClick={handleClose} style={{ color: "#0A2239" }}>
        <CloseIcon />
      </IconButton>

    </DialogTitle>
    <DialogContent style={{ paddingInline: "40px", paddingTop: "0px " }}>
      <Typography style={{
        color:"#0F172A",
        lineHeight:"32px",
        letterSpacing: "-0.12px",
        fontSize: "24px", fontWeight: 700, marginBottom: "12px",
        fontFamily: "Inter",
      }}>
        {firstHeading}
      </Typography>
      <Typography style={{ fontSize: "18px",color:"#334155", fontWeight: 400, fontFamily: "Inter", marginBottom: "20px", }}>
        {secondHeading}
      </Typography>
  { deletePopUp && <div>

            <Typography style={{ ...commentStyle.subheadingStyle, marginBottom: "4px" }}>Reason for deleting this post{starIcon()}
            </Typography>

            <InputField 
            style={{width:"100%",marginBottom:"20px"}}
            variant="outlined" placeholder="Enter reason for deleting this post"
                        error={deleteReasonError}
                        helperText={deleteReasonError&&"Please enter a value"}
                        value={deleteReason}
                       data-test-id="Reason_for_Editing"
                        onChange={
                           handleDeleteReason
                        } 
                        />
           
           
          </div>}
    </DialogContent>
    <DialogContent dividers style={{ justifyContent:"flex-end",display:"flex",gap:"16px",flexWrap:"wrap" }}>
    { FirstButtonName !=="" &&<ButtonComponent  onClick={handleClose} style={{height:"56px",}}>
              {FirstButtonName}
            </ButtonComponent>}
            <Button style={{...webstyle.BtnGreen2,height:"56px", marginLeft:"0px", fontFamily: "Inter",minWidth:137}} onClick={mainFunction}>
              {secondbuttonname}
            </Button>
    </DialogContent>

  </DiologStyle>
}
export const DiologComponent2 = ({ success, handleClose, firstHeading, secondHeading,secondbuttonname ,mainFunction }: any) => {
 
  return <DiologStyle onClose={handleClose} aria-labelledby="customized-dialog-title" open={success}>
    <DialogTitleStyle id="customized-dialog-title"
     >
      <IconButton aria-label="close" onClick={handleClose} style={{ color: "#0A2239",padding:4 }}>
        <CloseIcon />
      </IconButton>

    </DialogTitleStyle>
    <DialogContent style={{ paddingInline: "40px", paddingTop: "0px ",paddingBottom:"0px" }}>
      <Typography style={{
        color:"#0F172A",
        lineHeight:"32px",
        letterSpacing: "-0.12px",
        fontSize: "24px", fontWeight: 700, marginBottom: "12px",
        fontFamily: "Inter",
      }}>
        {firstHeading}
      </Typography>
      <Typography style={{ fontSize: "18px",
      lineHeight:"160%",
      color:"#334155", fontWeight: 400, fontFamily: "Inter", marginBottom: "24px", }}>
        {secondHeading}
      </Typography>
    </DialogContent>
    <DialogContent dividers style={{ justifyContent:"flex-end",display:"flex",gap:"16px",flexWrap:"wrap",padding:"24px" }}>
  
            <Button style={{...webstyle.BtnGreen2,height:"56px", marginLeft:"0px", fontFamily: "Inter",minWidth:151}} onClick={mainFunction}>
              {secondbuttonname}
            </Button>
    </DialogContent>

  </DiologStyle>
}
const DialogTitleStyle=styled(DialogTitle)({
  textAlign: "end" ,
'&.MuiDialogTitle-root':{
  padding:"16px 16px 12px 0px"
}
})
const CustomButton = styled(Button)({
  '@media (max-width: 1279px)': {
    flex: 1,
  },
  '& .MuiButton-label': {
    minWidth: 'max-content'
  }
})
export const ButtonComponent=styled(Button)({
  borderRadius: "8px",
  color: "03B5AA",
  fontFamily: "Inter",
  fontSize: "16px",
  width:"140px",
  height: "44px",
  fontWeight: 700,
  lineHeight: "24px",

  textTransform: "inherit",
  border: "1px solid #03B5AA",
  '@media (max-width: 409px)': {
   minWidth:"137px"
  },
})
export const DiologStyle = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "600px",
    minWidth: "600px",
    borderRadius: "8px 8px 32px 8px"
  },
  "@media (max-width:722px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 32px 8px"
    },
  },
})
const MainGrid = styled(Box)({
  padding: "55px 135px 134px 135px",
  '@media (max-width:1398px) and (min-width:960px)': {
      padding: "55px 40px 134px 20px;"
  },
  '@media (max-width:960px) and ( min-width:800px)': {
      padding: "55px 20px 134px 20px"
  },
  '@media (max-width:800px )': {
      padding: "55px 20px 134px 20px",
    
  },
})
const SnackBarBackDropStylIng = styled(Snackbar)({
  "&.MuiSnackbar-anchorOriginTopCenter":{
    top:"113px",
  }
  })
  const PetHealthBox=styled(Box)({

      display: 'flex',
      alignContent: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      padding: '32px 0',
  
  "@media(max-width:959px)":{
   
      justifyContent: 'flex-start'
    },
  
  }) 
  const TagBox=styled(Box)({
    
      background: '#CDF0EE80',
      height: 44,
      borderRadius: 26,
      padding: '0px 16px',
      color: '#074F57',
      fontSize: 16,
      lineHeight: '44px',
      fontWeight: 400,
      fontFamily: "Lato",
      textAlign: 'center',
   
      "@media(max-width:414px)":{
        fontSize: 15,
      },
  })
export const webstyle = {
  poPupStyle: {
    display: "flex",color: "#0F172A",
     padding:"9px",
    borderRadius: "8px",
    gap:"8px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    fontSize: "16px",
    lineHeight: "24px",boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
    fontWeight: 400,
    fontFamily: "Inter",
    
    
  },
  btnStylePopUp: {
    color: "#FFFFFF",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    width: "120px",
    height: "56px",
    background: "#00bdab",
    textTransform: "inherit" as "inherit",

  },
  Btn: {
    background: '#fff',
    border: '1px solid #CBD5E1',
    borderRadius: 8,
    padding: "10px 16px 10px 12px",
    lineHeight: '24px',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Lato",
    color: '#334155',
    marginLeft: 16,
  } as React.CSSProperties,
  BtnGreen: {
    background: '#03B5AA',
    border: '1px solid #03B5AA',
    borderRadius: 8,
    padding: "10px 16px 10px 12px",
    lineHeight: '24px',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "Lato",
    color: '#fff',
    marginLeft: 15,
    minWidth: 137,

  } as React.CSSProperties,
  BtnGreen2: {
    background: '#03B5AA',
    border: '1px solid #03B5AA',
    borderRadius: 8,
    lineHeight: '24px',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "Lato",
    color: '#fff',
    marginLeft: 15,
height:"46px"
  } as React.CSSProperties,
  popup: {
    display: "flex",
    gap:"8px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: "#0F172A",
    borderRadius: "8px",
  
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    textAlign:"center",
    padding:"9px 16px 9px 8px",
    lineHeight: "24px",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)"
  },
  iconstyle: {
    fontSize: 20,
    marginRight: 8,
  } as React.CSSProperties,
  list: {
    fontSize: "14px",
    lineHeight: '22px',
    fontWeight: 400,
    fontFamily: "Lato",
    color: "#475569",
  } as React.CSSProperties,
  dateBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: ' space-between',
  } as React.CSSProperties,
  dateLabel: {
    color: '#334155',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Lato",
  } as React.CSSProperties,
  boldate: {
    fontWeight: 600,
    marginLeft:"8px"
  } as React.CSSProperties,
  proTitle: {
    color: "#0F172A",
    fontSize: 30,
    fontWeight: 600,
    margin: '24px 0 12px',
   
    fontFamily: "Lato",
  } as React.CSSProperties,
  prosubTitle: {
    color: '#334155',
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 400,
    fontFamily: "Lato",
    margin: 0,
  } as React.CSSProperties,
  proInfo: {
    background: '#CDF0EE80',
    height: 44,
    borderRadius: 26,
    padding: '0px 16px',
    color: '#074F57',
    fontSize: 16,
    lineHeight: '44px',
    fontWeight: 400,
    fontFamily: "Lato",
    textAlign: 'center',
 
  } as React.CSSProperties,
  infoPara: {
    color: '#0F172A',
    fontSize: 18,
    lineHeight: '180%' as '180%',
    fontWeight: 400,
    fontFamily: "Lato" as "Lato",
    wordWrap: "anywhere" as "break-word",
    marginTop: "16px" as "16px",
  },
  
  popTitle: {
    color: 'rgb(15, 23, 42)',
    fontSize: 30,
    lineHeight: '36px',
    fontWeight: 600,
    margin: 0,
    fontFamily: "Lato",
  } as React.CSSProperties,
  popPara: {
    color: '#64748B',
    fontSize: 18,
    lineHeight: '30px',
    fontWeight: 400,
    fontFamily: "Lato",
    margin: '20px 0',
  } as React.CSSProperties,
  popFooter: {
    borderTop: '1px solid #CBD5E1',
    padding: 20,
  } as React.CSSProperties,
  textStyle: {
    padding: "12px 16px",
    cursor: "pointer",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px", width: "188px"
},
}
// Customizable Area End
