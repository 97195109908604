import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
import React from "react";
const baseUrl = require("../../../framework/src/config.js")
import {  heart, heartFill } from "./assets";
import moment from "moment";
import { getStorageData,removeStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: { name: string; price: number; description: string; images:{url:string}[] };
  postStatus:string;
  isloading: boolean;
  published_date:string|null;
  deletePopUp:boolean;
  openPublished:boolean;
  markUsAdoptedPopUp:boolean;
  markUsFosteresPopUp:boolean;
  focusedImageIndex: number;
  product_id:number;
  postId:any;
  postDetailsCatalogue:boolean
preview:boolean
userType:string
  isClassToggled:any;
post_name:string;
  postStatusChange: boolean;
  postStatus_id: string,
  postStatus_text: string,
  postActionsPublished: Array<any>,
  postActionsRejected: Array<any>,
  postStatus_label:string,
  message:string;
  anchorRef1:any
  postDetails:any;
  Added_to_favorites:boolean
  updatePost:boolean
  pathname:string;
  answer:string;
  isSnackbarOpenForRequest:any
  isSnackbarOpenForRequestMessage:any
  urlCopied: boolean
  already_answer_given:boolean;
  deleteReason:string;
  rescuer_email_true:boolean;
  rescuer_email_false:boolean;
  update_post_boolean:boolean;
  deleteReasonError:boolean;
  rescuer_date:string|null
  loader:boolean;
  isShowHideMarketingStrategy:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  getDetailPostID:string=""
  getDetailPostAdopterId:string=""
  deletePostId:string=""
  markUsFosteredId:string=""
  markAdoptedPostId:string=""
  favoriteAddApiId:string=""
  sharePostApiId:string=""
  remainderApiId:string="";
  getMarketingStrategyShowApi:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      // isloading: false,
      postDetails:[],
      postStatus:'',
      deleteReason:"",
      deleteReasonError:false,
  already_answer_given:false,
  rescuer_date:"",
  answer
:"",
  published_date:"",
  post_name:"",
  loader:false,
  openPublished:false,

      pathname:"",
      postId:null,
      userType:"",
      update_post_boolean:false,
      anchorRef1: React.createRef(),
      updatePost:false,
      Added_to_favorites:false,
      postDetailsCatalogue:true,
      message:"",
      productData: { name: "", price: 1, description: "", images: [{url:""}] },
      product_id: 8,
      focusedImageIndex:1,
      deletePopUp:false,
      preview:true,
            markUsAdoptedPopUp:false,
      markUsFosteresPopUp:false,
      isClassToggled: false,
      postStatusChange: false,
      postStatus_id: "", 
      postStatus_text: "", 
      isSnackbarOpenForRequest:false,
      isSnackbarOpenForRequestMessage:'',
      urlCopied: false,

      postActionsPublished: [
     
        { postStatus_id: 'Fostered', postStatus_text: 'Mark post as Fostered' },
        { postStatus_id: 'delete', postStatus_text: 'Delete Post' },
       
      ],
    
      postActionsRejected: [
        { postStatus_id: 'delete', postStatus_text: 'Delete Post' },
      ],
      postStatus_label:"published",
      rescuer_email_true:false,
      rescuer_email_false:false,
      isShowHideMarketingStrategy:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson ) {
        switch (apiRequestCallId) {
          case this.productApiItemCallId: 
          this.setState({ productData: responseJson.data.attributes });
            break;
            case this.remainderApiId:
                this.setState({openPublished:false})
     this.state.answer=="false"?
     setTimeout(() => {
      this.setState((state, props) => ({
        rescuer_email_true: true
      }), () => {
        setTimeout(() => {
          this.setState(() => ({
            rescuer_email_true: false
          }),()=>this.hanldeNavigateRequestPage('CataloguePage'))
        }, 5000);
      })
    }, 100):
      this.setState({
        rescuer_email_false: true
      })
              

              break;
          case this.getDetailPostID:   

          this.handleErrorResponse(responseJson)
          
         
          case this.getDetailPostAdopterId:
        
          this.handleErrorResponse(responseJson)
           
            break;
            case this.deletePostId:
              
              this.handleDeleetResponse(responseJson)

              break;
              case this.favoriteAddApiId:
              
                this.getDetailPostDataAdopter(this.state.postId);
                break;
              case this.markAdoptedPostId: 
              
this.handleResponseAdopted(responseJson)
                break;
                case this.markUsFosteredId: 
              

               this.handleResponse(responseJson)
                  break;
                  case this.getMarketingStrategyShowApi: this.setState({isShowHideMarketingStrategy:responseJson?.settings?.is_active})
                  break;
        }
        }         
     
    }

    // Customizable Area End
  }

  // Customizable Area Start
 handleResponse=(responseJson:any)=>{
    if(responseJson?.message=="Post status has been successfully changed to Fostered."){
      this.getDetailPostData(this.state.postId)
      
        this.setState((state, props) => ({
          message: 'Congratulations, the status of your post has been successfully changed to "Fostered."',
          updatePost: true
        }))
          setTimeout(() => {
            this.setState(() => ({
  
              message: "",
              updatePost: false
            }))
          }, 5000);
        
   
this.setState({markUsFosteresPopUp:false})
    }
  }
  handleFavBackground=()=>{
    return this.state.postDetails?.favourites?"linear-gradient(0deg, rgba(10, 34, 57, 0.40) 0%, rgba(10, 34, 57, 0.40) 100%), #FFF":"#fff"
  }
  handleFavWidth=()=>{
    return this.state.postDetails?.favourites?"193px":"175px"
  }
  handleStatusCondition=()=>{
  return   (this.state.postDetails?.status=="Published"||this.state.postDetails?.status=="Rejected")&&(this.state.preview&&this.state.pathname=="/petpost-description-owner")
  }
  handleHeadingImageFav=()=>{
   return  this.state.postDetails?.favourites? "Added to favorites":"Add to favorites"
  }
  handleListKeyDown(event:any) {
    if (event.key === 'Tab') {
      event.preventDefault();
    this.setState({isClassToggled:false}) 
    }
  }
  handleImageFav=()=>{
    return this.state.postDetails?.favourites?heartFill:heart
  }
  handleResponseAdopted=(responseJson:any)=>{
    if(responseJson?.message=="Post status has been successfully changed to Adopted."){
      
      storage.set("createPost",true)
      storage.set("Message","Post status has been successfully changed to Adopted.")
      this.props.navigation.navigate("CataloguePage")
      }
  }
  handleMicrochipped=()=>{
    return this.state.postDetails?.microchiped?"Yes":"No"
  }
  handleRescuerCondition=()=>{
    return (this.state.pathname=="/petpost-description-owner" || !this.state.preview )
  }
handleStatus=()=>{
  return this.state.postDetails?.status!=="Published"?"290px":"137px"
}
//Not in use
  handleCoatLength=()=>{
    return ( this.state.postDetails?.coat_length!==null) && ( this.state.postDetails?.coat_length!=="undefined" )&& (this.state.postDetails?.coat_length!=="null") ? this.state.postDetails.coat_length:"Not defined";
  }
  //Not in use
  handleLifeStyle=()=>{
    return (this.state.postDetails?.life_style!=="") && ( this.state.postDetails?.life_style!=="undefined" )&&( this.state.postDetails?.life_style!=="null")&&( this.state.postDetails?.life_style!==null) ? this.state.postDetails.life_style:"Not defined";
  }
  handleErrorResponse=(responseJson:any)=>{
    this.setState({loader:false})
  if(responseJson?.errors ||responseJson?.error){
    this.hanldeNavigateRequestPage('CataloguePage')
    }
    this.setState({ postDetails: responseJson?.data?.attributes });

    const queryParams = new URLSearchParams(window.location.search);
    const published = queryParams.get('published');
    const published_date = queryParams.get('published_date');
    const already_answer_given = queryParams.get('already_answer_given');
  
    
 
    if(already_answer_given=='true'){
      this.setState({already_answer_given:true})
     
    }
    if(responseJson?.data?.attributes?.pending_reminder){
   const post_name = queryParams.get('post_name')||"";
     if(published=="true"){
        const parsedDate = moment(published_date, "YYYY-MM-DD HH:mm:ss Z");

  const formattedDate = parsedDate.format("Do MMM YYYY");
        this.setState({openPublished:true,published_date:formattedDate,post_name:post_name})
      }
    }
    else if(!responseJson?.data?.attributes?.pending_reminder){
      if(published=="true"){
        this.setState({already_answer_given:true})
      }
    }
} 

handleDeleetResponse=(responseJson:any)=>{
  if(responseJson.message=="Post deleted succesfully!"){
    
    storage.set("createPost",true)
    storage.set("Message","The digital dust bunnies have been swept away. Your post is now a memory of Petooti's past.")
    this.props.navigation.navigate("CataloguePage")
  }
}
// not used
 handleActivityLevel=()=>{
    return( this.state.postDetails?.activity_level!=="undefined" ) && ( this.state.postDetails?.activity_level!=="" )&& (this.state.postDetails?.activity_level!==null)&& (this.state.postDetails?.activity_level!=="null")?this.state.postDetails?.activity_level:"Not defined"
  }
  handlerescuer_email_true=()=>{
  this.setState({rescuer_email_false:false})

  }
handlethisalready_answer_given =()=>{
  this.setState({already_answer_given:false})
  const currentUrl = new URL(window?.location?.href);
  currentUrl?.searchParams?.delete('already_answer_given');
  window?.history?.replaceState(null, '', currentUrl?.toString());
  
}
 // not used
  handleSize=()=>{
    return( this.state.postDetails?.size!=="undefined" )&& (this.state.postDetails?.size!==null)&&  (this.state.postDetails?.size!=="null")?this.state.postDetails?.size:"Not defined"
  }
  openInNewTab = (item:any) => {
    if(item?.dataURL){
      window.open(item.dataURL, '_blank')
    }
    else{
      const newTab = window.open( `${baseUrl.baseURL}${item.url}`, '_blank');
      
      if (newTab) {
        newTab.focus();
      }
    }
  };
  
   handleAgeFormat = () => {
    const { formatted_age } = this.state.postDetails;
  
    if (formatted_age?.years === 0 && formatted_age.months === 0) {
      return `Less than a month`;
    }
  
    let result = '';
  
    if (formatted_age?.years > 0) {
      result += `${formatted_age.years} ${formatted_age.years === 1 ? 'Year' : 'Years'}`;
    }
  
    if (formatted_age?.months > 0) {
      if (result.length > 0) {
        result += ' ';
      }
  
      result += `${formatted_age.months} ${formatted_age.months === 1 ? 'Month' : 'Months'}`;
    }
  
    return result;
  };
  handleGoodInHouseWith = () => {
    const { good_in_house_with } = this.state.postDetails
    
    if (good_in_house_with && good_in_house_with.length > 0 &&good_in_house_with[0]!=="" && ( this.state.postDetails?.good_in_house_with[0]!=="undefined" ) ) {
      let cleanedString =`"${good_in_house_with}"`.slice(1, -1);
    let resultArray = cleanedString.split(',').map(value => value.trim());

      const formattedList = resultArray.join(', ');
      return formattedList;
    }
    else{
      
      return 'Not defined';
      
    }
  }
  
  
  handleTemperament=()=>{
    const { temperament } = this.state.postDetails 
    if (temperament && temperament.length > 0) {
      let cleanedString =`"${temperament}"`.slice(1, -1);
      let resultArray = cleanedString.split(',');
      const formattedList = resultArray.join(', ');
      return formattedList;
    }
    return 'Not defined';
  }
   handleDate=()=>{
    if(this.state.postDetails?.status === 'Fostered'){
      return `Published on :`
    }
    else if(this.state.postDetails?.status === 'Pending'){
      return `Submitted on :`
    }
    else {
  return`${this.handleCheckForStatusTitle(this.state.postDetails?.status)} on :`
   }
  
   }
   handlePdfCollect=()=>{
    return this.state.postDetails?.vaccination_books&&  this.state.postDetails?.vaccination_books.length>0?

    this.state.postDetails?.vaccination_books?.map((item: any, index: number) => {
  
  
      return <span onClick={()=>{this.openInNewTab(item)}}
      data-test-id="openInNewTab"
    className="pdflink">{item?.name||item?.nameOfFile}</span>;
    })
  :
    'Not defined'
   }
  handleSpecialNeed=()=>{
    if(this.state.postDetails?.special_needs!==null  && ( this.state.postDetails?.special_needs!=="undefined" )){
      if(this.state.postDetails?.special_needs){
        return "Yes"
      }
      else{
        return "No"
      }
    }
    else{
      return "Not defined"
    }
  }
  handleClickAway=(e:any)=>{
    if(e.target.id!='Status'&&e.target.classList[0]!='MuiButton-label'){

      this.setState({ isClassToggled: !this.state.isClassToggled })
}
  }
  async componentDidMount() {
    this.getMarketingStrategyShow()
    const update_post_boolean:any=await getStorageData("update_post_boolean");
    const toastMessage=await getStorageData('toastMessage')
    this.setState({postStatus:toastMessage})
    if(update_post_boolean=="true"){
      
      setTimeout(() => {
        this.setState((state, props) => ({
   
          update_post_boolean: update_post_boolean
        }), () => {
          setTimeout(() => {
            this.setState(() => ({
              update_post_boolean: false,
              postStatus:''
            }))
            storage.set("update_post_boolean",false)
            removeStorageData("toastMessage")
          },5000);
        })
      }, 100);
    }
    const queryParams = new URLSearchParams(window.location.search);
    const rescuerAnswer = queryParams.get('rescuer_answer');

  
    
 
    const date = queryParams.get('date');
 
    if(rescuerAnswer=='true'){
      setTimeout(() => {
        this.setState((state, props) => ({
          rescuer_email_false: true
        }), () => {
          setTimeout(() => {
            this.setState(() => ({
              rescuer_email_false: false
            }),()=>this.hanldeNavigateRequestPage('CataloguePage'))
          }, 5000);
        })
      }, 100);
    }
    if(rescuerAnswer=='false'){
      this.setState({rescuer_date:date})
      this.setState({rescuer_email_true:true})
    }
  
    
    const postpath=window.location.pathname.split("/")
    const post_id=postpath[postpath.length - 1]

    const postDetails:any=this.props.navigation.history.location?.state?.postDetails;

  
    this.setState({postId:parseInt(post_id),pathname:"/"+postpath[1],postDetailsCatalogue:postDetails})
    
   this.setState({
    userType  : await storage.get("auth_user_role")
   }) 
   this.setState({loader:true})
if(window.location.pathname=="/previewProductDescription"){
this.setState({preview:false})
let responseJson: any;
  responseJson =await storage.get("preview_data");
  const data=await JSON.parse(responseJson)
if(data?.data?.attributes){
  this.setState({ postDetails: data?.data?.attributes});
  this.setState({loader:false})
}
}
else{
  if(postpath[1]==="petpost-description-owner"){

    this.getDetailPostData(parseInt(post_id))
  }else{
    this.getDetailPostDataAdopter(parseInt(post_id))

  }
}
const isState=this.props?.navigation?.history?.location?.state

if(isState?.isSnackBarOpen){
this.setState({postId:isState.id,isSnackbarOpenForRequest:isState.isSnackBarOpen,isSnackbarOpenForRequestMessage:isState.msg})
this.props?.navigation?.history?.replace()
}

  }

  handleBorderBottom=()=>{
    return this.state.postDetails?.fostered_by !==null  && this.state.pathname=="/petpost-description-owner" ?"1px solid #E2E8F0":"unset"
  }
  hanldeNavigateRequestPage=(path:any)=>{
    const queryParams = new URLSearchParams(window.location.search);
    const already_answer_given = queryParams.get('already_answer_given');
    let alreadyAns=already_answer_given=='true'
    if(alreadyAns){
    const currentUrl = new URL(window?.location?.href);
    currentUrl?.searchParams?.delete('already_answer_given');
    window?.history?.replaceState(null, '', '/my-posts');
    }
    
     
    const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
    msgsIs.addData(getName(MessageEnum.NavigationTargetMessage),path);
    msgsIs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {petId:this.state.postId,...(alreadyAns&&{state:already_answer_given})});
    msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsIs)
    
  }
  closeSnack=()=>{
    this.setState({updatePost:false})
  }
  getDetailPostData=async(id:number)=>{
    
    const header = {
      "content-Type": "application/json",
      "token":  await storage.get("auth_token") 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailPostID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${id}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

   
   }
   getDetailPostDataAdopter=async(id:number)=>{

    const header = {
      "content-Type": "application/json",
      "token":  await storage.get("auth_token") 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailPostAdopterId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${id}/public_post`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

   
   }
   handlePutDeletefunction=()=>{
    if(this.state.deleteReason=="" || this.state.deleteReason?.trim().length==0){
      this.setState({deleteReasonError:true})
    }
    else{
      this.setState({deleteReasonError:false})
      this.putDeletePost()

    }
   }
   putDeletePost=async()=>{
    const header = {
      "content-Type": "application/json",
      "token":  await storage.get("auth_token") 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePostId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${this.state.postId}?delete_reason=${this.state.deleteReason}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


   }
  
   handleFavPart=()=>{

    if(this.state.userType!=="" && this.state.userType!==null){
      this.handleFavoriteAdd()
    }
    else{
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
   }
   handleFavoriteAdd=async()=>{
   
    const header = {
      "content-Type": "application/json",
      "token":  await storage.get("auth_token") 
    };
     const body={
      'favouriteable_id':this.state.postId
     }
     const methodType=this.state.postDetails.favourites?"DELETE":"POST"
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favoriteAddApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      
      `bx_block_favourites/favourites`
     
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
   markAdoptedPost=async()=>{
    const header = {
      "content-Type": "application/json",
      "token":  await storage.get("auth_token") 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAdoptedPostId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${this.state.postId}/mark_as_adopted`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


   }
   markFosteredPost=async()=>{
    const header = {
      "content-Type": "application/json",
      "token":  await storage.get("auth_token") 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markUsFosteredId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${this.state.postId}/mark_as_fostered`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;


   }
openDropdown = () =>{
    this.setState((prevState) =>({
      isClassToggled: !prevState.isClassToggled  
    }))
  }
  handleClickOpen = (state:string) => {

    if(state=='Mark post as Adopted'){

      this.setState({
       markUsAdoptedPopUp:true
      })
    }
    else if(state=='Mark post as Fostered'){
      this.setState({
        markUsFosteresPopUp:true
      })
    }
    else if(state=='Delete Post'){
      this.setState({
        deletePopUp:true
      })
    }
  };

  handleDeleteClose = () => {
    this.setState({
      deletePopUp: false,
      deleteReason:""
    })
  };
  handleMarkUsAdoptedClose = () => {
    this.setState({
      markUsAdoptedPopUp: false,
    })
  };
  handleMarkUsFosteresClose = () => {
    this.setState({
      markUsFosteresPopUp: false,
    })
  };
  copyUrl = () => {
    alert("URL copied to clipboard!")
    const currentUrl ="Please have a look at this post from Petooti:  "+ window.location.href;
        navigator.clipboard.writeText(currentUrl)
        this. fallbackCopyTextToClipboard(currentUrl);
}

 fallbackCopyTextToClipboard=(text:string) =>{
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();

    document.body.removeChild(textarea);
}

handleCondtionForPublishedBy=():boolean=>{
  return (this.state.postDetails?.status=="Fostered"||this.state.postDetails?.status=="Published")&&this.state.pathname!=="/petpost-description-owner" 
  && (this.state.postDetails?.published_by['rescue_group']!==null

  || this.state.postDetails?.published_by.hasOwnProperty('full_name')
  || this.state.postDetails?.published_by.hasOwnProperty('image_url'))
}
  handleSharePost = async () => {
    const header = {
      "content-Type": "application/json",
      "token": await storage.get("auth_token")
    };

    const body = {
      post_id: this.state.postId
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sharePostApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_share/share`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  handleReminder = async (answer:string) => {
    this.setState({answer:answer})
    const header = {
      "content-Type": "application/json",
      "token": await storage.get("auth_token")
    };

    const body = {
      "post_id":parseInt(this.state.postId),
      "response":answer
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.remainderApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/post_reminders/post_published_reminder_answer`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  closePublishedPopUp=()=>{
    this.setState({openPublished:false})
  }
  handleDeleteReason = (e: any) => {
    
    const inputValue = e.target.value.trim(); 
if(inputValue.length==0){
  this.setState({ deleteReasonError: true,deleteReason: e.target.value.substring })
}
else  if ( (inputValue.length > 0 || inputValue === ' ')) {
        this.setState({ deleteReason: e.target.value, deleteReasonError: false });
    } 
}
handleShowPetBehaviour=()=>{
  
  const training=this.state.postDetails?.training
  const life_style=this.state.postDetails?.life_style
  if(training!==null&&training!==''&&training!==undefined||life_style!==null&&life_style!==''&&life_style!==undefined||this.state.postDetails?.good_in_house_with?.length>0&&this.state.postDetails?.good_in_house_with[0]!=='[]'||this.state.postDetails?.temperament?.length>0)
  {
    return true
  }else{
   return false 
  }
  
}
handleShowFields=(state:string|boolean)=>{
  if( state!==null&&state!==''&&state!==undefined) {
    return true
  }else{
    return false
  }
}
handleVaccinationBookName=()=>{
  if(this.state.postDetails?.category?.name=="Birds"||this.state.postDetails?.category?.name=="Reptiles"){
    return 'Ownership Certificate'
}
if(this.state.postDetails?.category?.name=="Little Critters"){
    return "Vaccination Book/Ownership Certificate"
}
else{
    return 'Vaccination Book'
}
}
getMarketingStrategyShow=async()=>{
  const headerIs = {

    "token": await getStorageData('auth_token')
  };
    
    const requestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMarketingStrategyShowApi = requestMessages.messageId

    requestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.marketing_Strategy_endPoint
    );
    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerIs)
    );

    requestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType)
      
      runEngine.sendMessage(requestMessages.id, requestMessages);
      return true;
 }
 handleCheckForStatusTitle=(status:string)=>{
  if(status=='Archived'){
    return 'Reserved'
  }else{
    return status
  }
 }
 
  // Customizable Area End
}
